.bookingTypeSkeleton-container {
    background-color: #EBEBEB;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* position: relative; */
    height: 150px;
    width:auto;
    align-items: center;
    justify-content: center;
  }
  
  .bookingTypeSkeleton-name-container,
  .bookingTypeSkeleton-address-container {
    padding-top: 8px;
    padding-bottom: 8px;  
      
  }
  
  .bookingType-name-skeleton,
  .bookingType-address-skeleton {
    background: #EBEBEB;
  }

  @media screen and (max-width: 768px){
    .bookingTypeSkeleton-container{
     /* width: 250px; */
     min-width: 300px;
     /* height: 180px */
    }
  }
  
  