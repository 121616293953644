
.bookingCardSkeleton-container{
    background-color: #EBEBEB;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 300px;
    margin: 0;
}
.bookingCardSkeleton-details-container{
    margin: 30px auto;
 } 
 .bookingCardSkeleton-booking-container{
    margin:10px 30px ;
 }
 .bookingCardSkeleton-court-id-container, .bookingCardSkeleton-date-time-container{
    display: flex;
    justify-content: space-between;
 }
 .bookingCard-name-skeleton,bookingCard-address-skeleton{
    padding-top: 8px;
 }
 .bookingCard-name-skeleton,
.bookingCard-location-skeleton{
  background: #cacaca;
}

@media screen and (max-width: 768px){
    .bookingCardSkeleton-container{
        margin: 12px;
        width: 160px;
        height: 162px;
    }
    .bookingCardSkeleton-details-container{
        margin: 10px auto;
     } 
     .bookingCardSkeleton-booking-container{
        margin:5px 20px ;
     }
}