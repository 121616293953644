@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

@font-face {
  font-family: aquawax;
  src: url("./fonts/AQUAWAXBLACK-R2K3.TTF");
}

@font-face {
  font-family: "helveticaneueltw06-93blkextRg";
  src: url("./fonts/helvetica_neueltw0693blkextobl-webfont.woff2")
      format("woff2"),
    url("./fonts/helvetica_neueltw0693blkextobl-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
input,
.filter-container-1,
textarea,
button,
select,
a,
div,
svg {
  -webkit-tap-highlight-color: transparent;
}
.error-page-container {
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-content: center;
  background: #020e23;
}

.landing-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  /*width: 100vw;*/
  position: relative;
}
/*.landing-page img {*/
/*  width: 100%;*/
/*  height: 74%;*/
/*  !*margin-left: 50px;*!*/
/*  background-size: cover;*/
/*}*/

.background-with-players {
  background: url("./asserts/GU Web Cover BG.webp") no-repeat;
  background-size: 100% 100%;
  /*background-size:contain ;*/
  height: 100dvh;
  /*width: 100vw;*/
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.background-wording {
  background: url("./asserts/BookNow.png") no-repeat;
  background-size: 100% 100%;
  /*background-size:contain ;*/
  height: 100dvh;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.background-players {
  background: url("./asserts/Players.webp") no-repeat;
  background-size: 100% 100%;
  /*background-size:contain ;*/
  height: 100dvh;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-3px);
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.background-explore-button {
  background: url("./asserts/exploreButton.png") no-repeat;
  background-size: cover;
  height: 57px;
  width: 285px;
  position: absolute;
  bottom: 4%;
  cursor: pointer;
  border-radius: 20px;
  animation: blink 1.5s ease-in-out infinite;
  transition: transform 0.2s ease-in-out;
}

.background-explore-button:hover {
  transform: scale(1.05);
}

nav {
  width: 100%;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
}
.logo {
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  margin-left: 50px;
  background-size: cover;
}
.logo img {
  width: 180px;

}
.nav-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.business-btn {
  margin-right: 20px;
  padding: 10px 13px;
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
  /*background: rgba(255, 255, 255, 0.5);*/
  border: 2px white solid;
  font-family: "Poppins", sans-serif;
}
.arrow-btn {
  display: flex;
  padding: 0;
  height: 55px;
  width: 55px;
  margin-right: 50px;
  border-radius: 50%;
  cursor: pointer;
  /*background: rgba(255, 255, 255, 0.5);*/
  align-items: center;
  justify-content: center;
  border: 2px white solid;
}
.arrow-btn svg {
  color: white;
}
.arrow-btn img {
  height: 50px;
  width: 50px;
  margin: 0;
  border-radius: 50%;
}

.wording {
  text-align: center;
}
.wording img {
  width: 600px;
  height: 200px;
}
.wording h1 {
  color: #f3f3f3;
  font-size: 40px;
  margin-top: 350px;
  margin-bottom: 60px;
  font-family: "Poppins", sans-serif;
}
.searchbar-container {
  /*bottom: 20%;*/
  align-items: center;
  text-align: center;
}
.searchbar {
  margin-top: -50px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.searchbar input {
  border: none;
  outline: none;
  padding: 20px 150px 20px 40px;
  font-family: "Poppins", sans-serif;
}

.search-input {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-image: url("./asserts/search-icon.png");
  background-position: 15px 17px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.location-input {
  background: #f2f2f2 url("./asserts/location-icon.png") no-repeat 10px 15px;
  background-size: 25px 25px;
}
.search-btn {
  background: #f2f2f2;
  padding: 7.5px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.search-btn button {
  border-radius: 20px;
  background: black;
  color: white;
  cursor: pointer;
  padding: 13px 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border: none;
}
.explore-btn {
  padding: 10px 29px;
  border-radius: 20px;
  border: none;
  font-weight: 700;
  background-image: url("./asserts/arrow-down-icon.png");
  background-position: 122px 7px;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  cursor: pointer;
  margin: 30px auto;
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgba(19, 19, 19, 0.6);
  box-shadow: 10px 5px 15px rgb(127, 209, 75);
}

.upcoming-bookings {
  margin: 40px;
  font-family: "Poppins", sans-serif;
  /*background: #15111E;*/
  background-color: #f1f5f9;
  border-radius: 20px;
  padding: 10px 39px 46px 39px;
  align-items: flex-start;
}
.upcoming-bookings > h1 {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #15111e;
  font-size: 30px;
}
.upcoming-booking-card svg {
  color: white;
  cursor: pointer;
  position: absolute;
  left: 45%;
  top: 80%;
  font-size: 40px;
  opacity: 0.6;
}

.upcoming-booking-card:not(:first-child) {
  margin-left: 10px;
}

.upcoming-booking-card {
  width: 300px;
  height: 300px;
  background-color: #0a5c36;
  position: relative;
  border-radius: 9px;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae,
    0 0 0 1px #34ee98, 0 0 0 1px #43ea80;
}
.expand-upcoming-booking-card {
  width: 300px;
  height: 300px;
  background-color: #0a5c36;
  position: relative;
  border-radius: 9px 9px 0 0;
  margin-right: 20px;
  box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae,
    0 0 0 1px #34ee98, 0 0 0 1px #43ea80;
}
.expand-card {
  width: 300px;
  height: 50px;
  background-color: #0a5c36;
  position: relative;
  border-radius: 0 0 9px 9px;
  margin-right: 20px;
  /*box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae, 0 0 0 1px #34ee98, 0 0 0 1px #43ea80;*/
  border-top: none;
}
.booking-cancel-btn {
  width: 200px;
  height: 30px;
  position: absolute;
  top: -14%;
  left: 18%;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  color: #c21807;
  font-weight: 600;
}
.closed-card {
  display: none;
}
.upcoming-booking-card h1 {
  font-weight: 600;
  font-size: x-large;
  position: absolute;
  top: 3%;
  /* left: 28%; */
  /*color: #15111E;*/
  color: white;
  width: 280px;
  justify-content: center;
  text-align: center;

}
.upcoming-booking-card h3 {
  font-weight: 400;
  font-size: small;
  position: absolute;
  top: 14%;
  /* left: 38%; */
  /*color: #15111E;*/
  color: white;
  width: 280px;
  justify-content: center;
  text-align: center;

}
.line {
  width: 75%;
  height: 2px;
  border-bottom: 2px dashed #c7bebe;
  position: absolute;
  top: 37%;
  left: 12%;
}
.half-circle {
  top: 35%;
  transform: translate(-50%, -50%);
  height: 15px;
  width: 30px;
  border-radius: 150px 150px 0 0;
  background-color: #f1f5f9;
  position: absolute;
  /*box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae, 0 0 0 0px #34ee98, 0 0 0 1px #43ea80;*/
  border-bottom: 0px solid green;
  border-right: 1px solid #2af5c2;
  border-left: 1px solid #29f2ae;
  border-top: 1px solid #43ea80;
}
.upcoming-booking-card .court-heading {
  position: absolute;
  top: 40%;
  left: 13%;
  font-size: x-small;
  font-weight: 600;
  color: #ccc;
}
.upcoming-booking-card .booking-id-heading {
  position: absolute;
  top: 40%;
  right: 13%;
  font-size: x-small;
  font-weight: 600;
  color: #ccc;
}
.upcoming-booking-card .date-heading {
  position: absolute;
  top: 60%;
  left: 13%;
  font-size: x-small;
  font-weight: 600;
  color: #ccc;
}
.upcoming-booking-card .time-heading {
  position: absolute;
  top: 60%;
  right: 13%;
  font-size: x-small;
  font-weight: 600;
  color: #ccc;
}
.upcoming-booking-card .court {
  position: absolute;
  top: 44%;
  left: 16%;
  font-weight: 600;
  font-size: small;
  /*color: #15111E;*/
  color: white;
}
.upcoming-booking-card .booking-id {
  position: absolute;
  top: 44%;
  right: 12%;
  font-weight: 600;
  font-size: small;
  color: #74ab3d;
}
.upcoming-booking-card .date {
  position: absolute;
  top: 64%;
  left: 13%;
  font-weight: 600;
  font-size: small;
  /*color: #15111E;*/
  color: white;
}
.upcoming-booking-card .time {
  position: absolute;
  top: 64%;
  right: 12%;
  font-weight: 600;
  font-size: small;
  /*color: #15111E;*/
  color: white;
}

.left {
  left: -9px;
  transform: rotate(90deg);
}

.right {
  right: -9px;
  transform: rotate(270deg);
}

/*.react-multi-carousel-track {*/
/*  width: 100%;*/
/*}*/

.no-futsal-container {
  background: white;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 400px;
}
.futsal-container {
  /*background-color:#f1f5f9 ;*/
  background-color: #020e23;
  margin: 0 40px;
  border-radius: 20px;
  padding: 30px;
  margin-top: 50px;
}
.futsal-container ul.react-multi-carousel-dot-list {
  background: none;
  /*width: 250px;*/
  /*height: 50px;*/
  margin: 0 auto;
  border-radius: 5px;
}
.futsal-container li.react-multi-carousel-dot {
  width: 50px;
  height: 14px;
  border-radius: 0;
  position: relative;
}
.futsal-container ul.react-multi-carousel-dot-list button {
  width: 50px;
  height: 8px;
  border-radius: 0;
  border: none;
  background: rgba(225, 222, 222, 0.2);
  transform: skew(-20deg);
  margin: 0;
}
.futsal-container
  li.react-multi-carousel-dot.react-multi-carousel-dot--active
  button {
  width: 50px;
  height: 12px;
  background: #1f8900;
  border: none;
}
.futsal-container h1,
p {
  font-family: "Poppins", sans-serif;
}
.futsal-container h1 {
  font-weight: 600;
  padding-left: 20px;
  color: white;
}
.futsal-container > p {
  color: #64748b;
  letter-spacing: 0.19px;
  font-weight: 400;
  padding-left: 20px;
  margin-top: -19px;
}
.futsal-card-stroke {
  margin: 10px 40px 60px 5px;
  background: transparent;
  width: 238px;
  height: 284px;
  box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae,
    0 0 0 1px #34ee98, 0 0 0 1px #43ea80;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 14px;
}
.futsal-card-container {
  width: 215px;
  background: white;
  cursor: pointer;
  border-radius: 8px;
  padding: 7px 7px 12px 7px;
  position: relative;
  height: 256px;
}
.futsal-container > .react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  /*margin:0 30px 0 -10px*/
  /*background: red;*/
  margin: 0 30px;
}

/*.futsal-card-container::before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: -5px;*/
/*  bottom: -5px;*/
/*  left: -5px;*/
/*  right: -5px;*/
/*  z-index: -1;*/
/*  border: 1px solid;*/
/*  border-image: linear-gradient(to right top, #38f8d4, #2af5c2, #29f2ae, #34ee98, #43ea80);*/
/*  border-image-slice: 1;*/
/*  border-radius: 10px;*/
/*  background-clip: padding-box; !* Add this line *!*/
/*}*/

.futsal-card-container img {
  border-radius: 8px;
  width: 100%;
  height: 134px;
  margin: 0 auto;
}

.futsal-card-container h3,
.futsal-card-container p {
  font-family: "Poppins", sans-serif;
}
.sorting-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.location-default-btn {
  margin: auto 5px;
  border: none;
  color: #64748b;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  text-transform: capitalize;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}
.location-selected-btn {
  background: #74ab3d;
  color: #ffffff;
  margin: auto 5px;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  text-transform: capitalize;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}
.viewAll-button {
  color: white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}
.futsal-card-end {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 40px;
}
.first-box {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
}
.futsal-body {
  padding: 0 10px 0 10px;
}
.first-word-futsal-name {
  font-size: 23px;
  text-align: center;
  font-weight: 700;
  font-family: "helveticaneueltw06-93blkextRg", serif;
  margin: 0;
  padding: 10px 0 0 0;
}
.second-word-futsal-name {
  font-size: 23px;
  text-align: center;
  font-weight: 700;
  font-family: "helveticaneueltw06-93blkextRg", serif;
  margin-top: -10px;
}
hr.line1 {
  border-top: 1px solid #edecec;
  margin-bottom: 0px;
  margin-top: -8px;
}
.futsal-body h3 {
  font-size: 10px;
  color: rgb(181, 179, 179);
  font-weight: 400;
  margin-top: -10px;
}
.place-p {
  font-size: x-small;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-top: -13px;
}
.futsal-card-end span {
  font-weight: 300;
}
.price-button {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.futsal-card-end h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  background: #1f8900;
  color: white;
  padding: 3px 10px;
  border-radius: 5px;
  margin: 0;
  font-size: 14px;
}
.futsal-card-end p {
  color: black;
  font-weight: 400;
}
.rating {
  /*margin-bottom: -5px;*/
  align-items: center;
  display: flex;
  justify-content: left;
  font-size: small;
  font-family: "Poppins", sans-serif;
}
.rating span {
  color: #ccc;
}
.rating svg {
  color: #ffb900;
}
/* footer-css */
.footer-image-green {
  width: calc(50% - 2px);
  max-width: 150px;
  right: 0;
  position: absolute;
}
hr {
  margin-bottom: 0;
}
.footer-container {
  display: flex;
  justify-content: flex-end;
  /*margin: 60px 200px 60px 64px;*/
  background: url("./asserts/GU Footer.webp") no-repeat center;
  background-size: 100% 100%;
  height: 450px;
  padding: 0;
}
.footer-logo {
  width: 200px;
  height: 80px;
  cursor: pointer;
}
.footer-logo-column > p {
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.footer-input {
  font-family: "Poppins", sans-serif;
  padding: 13px 15px 13px 13px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #000;
  border-radius: 4px;
  font-size: 13px;
  outline: none;
  width: 65%;
}

/* src/TermsAndCondition.css */
.policy-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.policy-popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 700px;
  max-height: 80%;
  display: flex;
  flex-direction: column;
}

.policy-popup-body {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
}

.policy-popup-close-button {
  align-self: center;
  padding: 10px 40px;
  background: #33b249;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.policy-popup-close-button:hover {
  background: #1f8900;
}


.subscribe-btn {
  background-color: #74ab3d;
  padding: 12px 15px;
  border: 1px #a3a3a3 solid;
  border-radius: 5px;
  /* box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%); */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.border-glow {
  padding: 10px;
  margin: auto 80px auto 0;
  position: relative;
  border-radius: 20px;
  /*overflow: hidden;*/
}
.border-glow::before {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 30px;
  border: 3px solid transparent;
  background: linear-gradient(
      to right top,
      #38f8d4,
      #2af5c2,
      #29f2ae,
      #34ee98,
      #43ea80
    )
    border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  box-shadow: 0 0 10px rgba(67, 233, 162, 0.8);
}

.steps-container {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-box {
  display: flex;
  align-items: center;
  margin: auto 40px;
  height: 110px;
}
.step-box-image {
  width: 75px;
  height: 75px;
  border: 1px solid #41c335;
  padding: 15px;
  border-radius: 20px;
}
.step-box-wording {
  margin-left: 20px;
}
.step-box-wording h1 {
  font-family: "Poppins", sans-serif;
}

.step-box-wording h1:nth-child(2) {
  margin-top: -40px;
  color: #297216;
}

.welcome-to-gearup-container {
  background: #020e23;
  height: 650px;
  position: relative;
  overflow: hidden;
}
.welcome-to-gearup-container::after {
  content: "";
  position: absolute;
  top: -350px;
  right: -175px;
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background-color: rgba(121, 232, 64, 0.8);
  box-shadow: 0 250px 2000px 50px rgba(121, 232, 64, 0.5);
}
.welcome-to-gearup-container .content-container {
  width: 750px;
  height: 379px;
  margin: 130px auto 0 auto;
  display: flex;
}
.welcome-to-gearup-container .content-container img {
  width: 300px;
}
.gearup-players-container {
  position: relative;
  z-index: 1;
}
.gearup-players-container::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(121, 232, 64, 0.8);
  box-shadow: 0 0 80px 50px rgba(121, 232, 64, 0.5);
  z-index: -1;
}
.welcome-to-gearup-container .content-container .wording-container {
  font-family: "Poppins", sans-serif;
  margin-left: 20px;
  color: white;
}
.welcome-to-gearup-container .content-container .wording-container hr {
  height: 3px;
  background: #4b8b37;
  border: none;
  margin-top: -15px;
}
.welcome-to-gearup-container .content-container .wording-container p {
  font-size: 13px;
}
.welcome-to-gearup-container .content-container .wording-container h1 span {
  color: #77e53e;
}
.welcome-to-gearup-container
  .content-container
  .wording-container
  h1:nth-of-type(1) {
  margin-top: -25px;
  font-size: 40px;
}
.welcome-to-gearup-container
  .content-container
  .wording-container
  h1:nth-of-type(2) {
  margin-top: -45px;
  font-size: 40px;
}
.welcome-to-gearup-container .content-container .wording-container button {
  border-radius: 5px;
  padding: 7px 20px;
  background: transparent;
  font-weight: 600;
  color: #7cd44e;
  border: 1px solid #7cd44e;
  cursor: pointer;
}

.footer-links-all {
  display: flex;
  background: white;
  width: 300px;
  height: 300px;
  margin: 0;
  border-radius: 20px;
  justify-content: space-around;
}
.footer-links,
.footer-social {
  display: flex;
  flex-direction: column;
}
.footer-links > a,
.footer-social > a {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #000;
  text-decoration: none;
  margin-bottom: 20px;
}
.footer-social svg {
  fill: #000;
  width: 30px;
  /*height: 20px;*/
  margin-right: 10px;
}
.footer-social {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.footer-social a {
  display: flex;
  justify-content: center;
}
.footer-horizontal-line {
  border: 1px solid #000;
  margin: 10px 64px;
}
.footer-copyright {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 0;
  background: #131220;
  padding: 8px 28px;
  color: white;
}

.footer-copyright-links > a {
  text-decoration: underline;
  color: white;
  cursor: pointer;
  margin-left: 10px;
}
.footer-copyright-links > button{
  text-decoration: underline;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  background: none;
  border: none;
}
.futsal-banner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.futsal-banner img {
  width: 90vw;
  margin: 0 auto;
}
.banner-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .error-page-container img {
    width: 85%;
    height: 50%;
    margin: auto;
  }
  .no-futsal-container {
    margin: 20px;
    height: 250px;
  }
  .no-futsal-container img {
    height: 80%;
    margin: auto;
  }
  .landing-page {
    background: url("./asserts/GU Web Cover BG.webp") no-repeat center;
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
    width: 100%;
  }
  .background-with-players {
    display: none;
  }

  .background-wording {
    display: none;
  }
  .background-players {
    display: none;
  }
  .background-explore-button {
    height: 40px;
    width: 200px;
  }
  .upcoming-bookings {
    margin: 0;
    font-family: "Poppins", sans-serif;
    background-color: #f1f5f9;
    border-radius: 20px;
    padding: 10px 0;
  }
  .upcoming-bookings > h1 {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #15111e;
    font-size: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .upcoming-booking-card svg {
    color: white;
    cursor: pointer;
    position: absolute;
    left: 45%;
    top: 83%;
    font-size: 20px;
  }

  .upcoming-booking-card:not(:first-child) {
    /*margin: 0 auto 20px;*/
    margin-left: 0;
  }

  .upcoming-booking-card {
    width: 162px;
    height: 160px;
    /*margin:0 auto 40px;*/
    margin: 0 0 0 20px;
  }
  .expand-card {
    width: 162px;
    margin: 0 0 0 20px;
    display: inline-block;
    height: 50px;
  }
  .booking-cancel-btn {
    width: 130px;
    height: 25px;
    position: absolute;
    top: 8%;
    left: 11%;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    color: #c21807;
    font-weight: 600;
    font-size: 10px;
  }
  .upcoming-booking-card h1 {
    font-weight: 600;
    font-size: small;
    position: absolute;
    top: 9%;
    /* left: 24%; */
    /*color: #15111E;*/
    color: white;
    width: 160px;
  }
  .upcoming-booking-card h3 {
    font-weight: 400;
    font-size: 10px;
    position: absolute;
    top: 17%;
    /* left: 33%; */
    /*color: #15111E;*/
    color: white;
    width: 160px;
  }
  .line {
    width: 75%;
    height: 2px;
    border-bottom: 2px dashed #c7bebe;
    position: absolute;
    top: 37%;
    left: 12%;
  }

  .upcoming-booking-card .court-heading {
    position: absolute;
    top: 40%;
    left: 13%;
    font-size: 7px;
    font-weight: 600;
    color: #ccc;
  }
  .upcoming-booking-card .booking-id-heading {
    position: absolute;
    top: 40%;
    right: 13%;
    font-size: 7px;
    font-weight: 600;
    color: #ccc;
  }
  .upcoming-booking-card .date-heading {
    position: absolute;
    top: 60%;
    left: 13%;
    font-size: 7px;
    font-weight: 600;
    color: #ccc;
  }
  .upcoming-booking-card .time-heading {
    position: absolute;
    top: 60%;
    right: 13%;
    font-size: 7px;
    font-weight: 600;
    color: #ccc;
  }
  .upcoming-booking-card .court {
    position: absolute;
    top: 46%;
    left: 16%;
    font-weight: 600;
    font-size: 10px;
    /*color: #15111E;*/
    color: white;
  }
  .upcoming-booking-card .booking-id {
    position: absolute;
    top: 47%;
    right: 12%;
    font-weight: 600;
    font-size: 10px;
    color: #74ab3d;
  }
  .upcoming-booking-card .date {
    position: absolute;
    top: 67%;
    left: 13%;
    font-weight: 600;
    font-size: 7px;
    /*color: #15111E;*/
    color: white;
  }
  .upcoming-booking-card .time {
    position: absolute;
    top: 67%;
    right: 12%;
    font-weight: 600;
    font-size: 7px;
    /*color: #15111E;*/
    color: white;
  }

  .left {
    left: -9px;
    transform: rotate(90deg);
  }

  .right {
    right: -9px;
    transform: rotate(270deg);
  }

  .location-default-btn {
    margin: auto 5px;
    font-size: 16px;
    padding: 10px 20px;
  }
  .location-selected-btn {
    font-size: 16px;
    padding: 10px 14px;
  }
  .viewAll-button {
    font-size: 16px;
    padding: 10px 15px;
  }
  .futsal-banner img {
    width: 90vw;
    margin: 30px auto;
  }
  .banner-pc {
    display: none;
  }
  .banner-mobile {
    display: block;
  }

  .footer-image-green {
    width: 100%;
  }
  .footer-container {
    display: flex;
    justify-content: flex-end;
    /*margin: 60px 200px 60px 64px;*/
    background: url("./asserts/GU Footer Mob.webp") no-repeat center;
    background-size: cover;
    height: 106px;
    padding: 0;
  }
  .border-glow {
    padding: 5px;
    margin: auto 25px auto 0;
    position: relative;
    border-radius: 20px;
    /*overflow: hidden;*/
  }
  .border-glow::before {
    border: 2px solid transparent;
    border-radius: 15px;
  }

  .steps-container {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .step-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 20px;
    height: 115px;
  }
  .step-box-image {
    width: 45px;
    height: 45px;
    border: 1px solid #41c335;
    padding: 15px;
    border-radius: 20px;
  }
  .step-box-wording {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  .step-box-wording h1 {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
  }
  .step-box-wording h1:nth-child(2) {
    margin-top: -15px;
  }

  .welcome-to-gearup-container {
    margin-top: 0;
    background: #020e23;
    height: 685px;
    position: relative;
    overflow: hidden;
  }
  .welcome-to-gearup-container::after {
    content: "";
    position: absolute;
    top: -350px;
    right: -175px;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: rgba(121, 232, 64, 0.8);
    box-shadow: 0 250px 1500px 50px rgba(121, 232, 64, 0.5);
  }
  .welcome-to-gearup-container .content-container {
    flex-direction: column;
    width: 300px;
    align-items: center;
    height: auto;
    margin: 50px auto 0 auto;
  }
  .welcome-to-gearup-container .content-container img {
    width: 200px;
  }

  .welcome-to-gearup-container
    .content-container
    .wording-container
    h1:nth-of-type(1) {
    margin-top: -22px;
    font-size: 25px;
  }
  .welcome-to-gearup-container
    .content-container
    .wording-container
    h1:nth-of-type(2) {
    margin-top: -22px;
    font-size: 25px;
  }

  .footer-links-all {
    width: 65px;
    height: 60px;
    border-radius: 10px;
  }
  .footer-logo-column {
    text-align: center;
  }
  .footer-image-green {
    max-width: 100px;
  }
  .footer-links {
    display: none;
  }
  .footer-links > a {
    font-size: 8px;
    margin-bottom: 0;
  }
  .footer-input {
    margin-bottom: 10px;
    padding: 10px 12px 10px 10px;
  }
  .footer-social span {
    display: none;
  }
  .footer-social {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: center;*/
    width: 100%;
  }
  .footer-social svg {
    width: 15px;
    /*height: 15px;*/
    margin: 0;
  }
  .footer-copyright {
    font-size: 10px;
  }
  .footer-links > a,
  .footer-social > a {
    margin: 6px auto;
  }
  .footer-social > h4 {
    font-size: 14px;
    margin: 0;
    display: none;
  }
  .subscribe-btn {
    padding: 7px 10px;
  }
  .footer-copyright {
    flex-direction: column;
    align-items: center;
    /*margin: 10px;*/
  }
  .footer-copyright-links {
    margin-bottom: 10px;
    margin-top: 5px;
    display: none;
  }
  .footer-copyright-links a {
    text-decoration: none;
  }
  /*.footer-links-all > div{*/
  /*  margin: 25px;*/
  /*}*/
}

@media screen and (max-width: 768px) {
  .searchbar {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .searchbar input {
    padding: 13px 0 13px 3px;
    text-align: center;
  }
  .business-btn {
    display: none;
  }
  .wording h1 {
    font-size: 24px;
    margin-top: 0px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
  }
  .landing-page img {
    /*display: none;*/
  }

  .nav-container {
    /*background-color: white;*/
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  /*.landing-page .nav-container .logo img {*/
  /*  margin-left: 20px;*/
  /*  width: 145px;*/
  /*}*/

  .logo {
   margin-left: 0;
  }
  .logo img {
   margin-left: 45px;
  }

  .arrow-btn {
    border: 1px solid lightgrey;
    margin-right: 20px;
    /*margin-top: 10px;*/
    width: 43px;
    height: 43px;
  }

  .arrow-btn img {
    display: flex;
    width: 40px;
    height: 40px;
  }

  .wording {
    margin-top: 20px;
  }

  .wording img {
    width: 100%;
    display: inline;
    height: 110%;
  }
  .landing-page {
    padding-bottom: 1px;
    height: 70vh;
  }
  .wording {
    text-align: center;
  }
  .search-input {
    border-radius: 25px;
    background-image: url("./asserts/search-icon.png");
    background-position: 13px 15px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding: 0;
  }
  .location-input {
    background: #f2f2f2 url("./asserts/location-icon.png") no-repeat 10px 10px;
    background-size: 25px 25px;
    border-radius: 25px;
    margin-top: 20px;
    padding: 0;
  }
  .sorting-buttons {
    margin: 20px 5px;
  }
  .location-default-btn {
    font-size: 11px;
  }
  .location-selected-btn {
    font-size: 13px;
  }
  .viewAll-button {
    font-size: 13px;
  }
  .search-btn {
    background: #f2f2f2;
    padding: 3px;
    border-radius: 25px;
    margin-top: 20px;
  }

  .search-btn button {
    padding: 10px 60px;
  }
  .searchbar-container {
    margin-top: 50px;
  }

  .futsal-container ul.react-multi-carousel-dot-list {
    background: none;
    /*width: 250px;*/
    /*height: 50px;*/
    margin: 0 auto;
    border-radius: 5px;
  }
  .futsal-container li.react-multi-carousel-dot {
    width: 30px;
    height: 14px;
    border-radius: 0;
    position: relative;
  }
  .futsal-container ul.react-multi-carousel-dot-list button {
    width: 30px;
    height: 8px;
    border-radius: 0;
    border: none;
    background: rgba(225, 222, 222, 0.2);
    transform: skew(-20deg);
    margin: 0;
  }
  .futsal-container
    li.react-multi-carousel-dot.react-multi-carousel-dot--active
    button {
    width: 30px;
    height: 10px;
    background: #1f8900;
    border: none;
  }

  .futsal-container {
    width: 100%;
    margin: 0;
    padding: 0;
    align-items: center;
  }
  .futsal-container {
    margin-top: 0;
    padding: 30px 0;
    border-radius: 0;
  }
  .futsal-card-container img {
    width: 100%;
    height: 87px;
  }
  .second-word-futsal-name {
    font-size: 23px;
    text-align: center;
    font-weight: 700;
    font-family: "helveticaneueltw06-93blkextRg";
    margin-top: -6px;
  }
  .futsal-card-end h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 8px;
    background: #1f8900;
    color: white;
    padding: 4px 5px;
    border-radius: 5px;
    margin: 0;
    margin-left: 6px;
  }
  .place-p {
    font-size: 8px;
  }

  hr.line1 {
    border-top: 1px solid #edecec;
    margin-bottom: 5px;
  }
  .futsal-container h1 {
    text-align: center;
    font-size: 22px;
    margin: 15px;
  }
  .futsal-body h2 {
    font-size: 15px;
  }
  .futsal-card-end {
    height: 32px;
  }
  .futsal-body p {
    font-size: 10px;
  }
  .futsal-container > p {
    text-align: center;
    margin: 0;
  }
  .futsal-card-stroke {
    margin: 10px auto 36px;
    background: transparent;
    width: 164px;
    height: 198px;
    box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae,
      0 0 0 1px #34ee98, 0 0 0 1px #43ea80;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 14px;
  }

  .futsal-card-container {
    width: 145px;
    height: 173px;
    border-radius: 14px;
  }
  .futsal-container > .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    /*margin:0 0 0 20px ;*/
    margin: 0;
    width: 100%;
  }
}

.courts-nav-container {
  height: auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid transparent;
  padding: 0 50px;
  /*border-image: linear-gradient(to right, #38f8d4, #2af5c2, #29f2ae, #34ee98, #43ea80);*/
  border-bottom: 2px solid black;
  border-image-slice: 1;
  align-items: center;
  background-color: white;
}

.nav-container .sign-in-buttons {
  z-index: 99;
  margin-right: 50px;
}

.courts-nav-container .sign-in-buttons a {
  padding: 5px 20px 5px 20px;
  margin-right: 20px;
  border-radius: 40px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}
.nav-container .sign-in-buttons a {
  padding: 5px 20px 5px 20px;
  margin-right: 20px;
  border-radius: 40px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.courts-nav-container .sign-in-buttons .sign-in-btn {
  border: 1px solid black;
  color: black;
  padding: 5px 27px 5px 27px;
}
.nav-container .sign-in-buttons .sign-in-btn {
  /*border: 2px solid #33b249;*/
  border: 1px solid white;
  color: white;
  padding: 5px 27px 5px 27px;
}

.courts-nav-container .sign-in-buttons .sign-up-btn:hover {
  background-color: #33b249;
}
.nav-container .sign-in-buttons .sign-up-btn:hover {
  background-color: #33b249;
}

.courts-nav-container .sign-in-buttons .sign-up-btn {
  /*background: linear-gradient(to right, #38f8d4, #2af5c2, #29f2ae, #34ee98, #43ea80);*/
  color: white;
  background: black;
  padding: 7px 17px 7px 17px;
  text-align: center;
  align-items: center;
}
.nav-container .sign-in-buttons .sign-up-btn {
  color: white;
  background: black;
  padding: 7px 17px 7px 17px;
  text-align: center;
  align-items: center;
}

.courts-nav-container .courts-nav-btns {
  display: block;
  margin-right: 10px;
}

.courts-nav-container .courts-logo {
  display: flex;
  justify-content: center;
}

.courts-nav-container .courts-logo img {
  height: 60px;
  cursor: pointer;
}

.courts-profile-btn {
  /*margin: 5px 10px;*/
  border-radius: 50%;
  width: 50px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border: 2px lightgrey solid;
  height: 50px;
  display: flex;
  justify-content: center;
}

.courts-profile-btn img {
  border-radius: 50%;
  height: 40px;
}

.profile-dropdown {
  position: relative;
  display: inline-block;
}

.profile-dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  /*min-width: 120px;*/
  width: 250px;
  margin-top: 10px;
  margin-right: 40px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
}

.profile-dropdown-content .dropdown-edit-profile {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.profile-dropdown-content .dropdown-edit-profile img {
  border-radius: 50%;
  height: 40px;
  object-fit: scale-down;
  margin: 20px;
  margin-right: 5px;
}

.profile-dropdown-content .dropdown-edit-profile .profile-and-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-dropdown-content .dropdown-edit-profile .nav-edit-profile {
  background: none;
  border: none;
  cursor: pointer;
  color: grey;
}

.profile-dropdown-content > a {
  text-align: center;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  /*display: block;*/
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.profile-dropdown-content > button {
  border: none;
  text-align: center;
  color: black;
  padding: 12px 165px 12px 16px;
  text-decoration: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: white;
  cursor: pointer;
  font-size: 16px;
}
.dropdown-edit-profile > a {
  text-align: center;
  color: black;
  padding: 0 25px;
  text-decoration: none;
  /*display: block;*/
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.profile-dropdown-content a > svg {
  /*margin-right: 8px; !* Add space between icon and text *!*/
  font-size: 24px; /* Change the icon size */
  vertical-align: middle; /* Align the icon vertically */
}

.profile-dropdown-content a:hover,
.profile-dropdown-content button:hover {
  background-color: #f1f1f1;
}

.courts-section {
  margin: 0;
  padding: 0 10%;
  font-family: "Poppins", sans-serif;
}

.book-btn {
  color: white;
  background-color: black;
  padding: 12px 15px;
  border: 1px #a3a3a3 solid;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.court-details-header {
  display: flex;
  flex-direction: column;
}

.courts-section-body {
  margin: 20px;
}

.court-header-container {
  padding: 0 10px;
}

.court-header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.mobile-court-name > h1 {
  text-align: center;
  margin: 0px;
  font-family: "helveticaneueltw06-93blkextRg", serif;
  font-size: 3rem;
  display: none;
}

.court-header-content > h1 {
  text-align: center;
  margin: 0px;
  font-family: "helveticaneueltw06-93blkextRg", serif;
  font-size: 3rem;
}

.court-header-content button {
  position: absolute;
  right: 0%;
  top: 10%;
  border-radius: 5px;
  background: #33b249;
  color: white;
  cursor: pointer;
  padding: 8px 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: none;
  font-size: 25px;
}

.court-header-extra-content {
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.court-header-extra-content p {
  margin-right: 10px;
  margin-top: 0px;
  padding-left: 10px;
}

.court-header-extra-content ul {
  margin-top: 0;
  padding-left: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.review-total {
  color: #6950f3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.16px;
  text-transform: capitalize;
  margin-left: 5px;

}

.court-header-extra-content li {
  display: flex;
  margin-right: 10px;
  list-style-type: disc;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.16px;
  text-transform: capitalize;
  font-size: 16px;
  align-content: center;
  text-align: center;
  justify-content: center;
}
.review-li{
  display: flex;
  align-items: center;
  text-align: center;
}
.review-li svg{
  font-size: 20px;
}
.review-select-container {
  display: flex;
  justify-content: space-between;
}

.drop-btn {
  background-color: #fff;
  color: black;
  font-weight: 600;
  padding: 10px 30px;
  border: 1px solid black;
  cursor: pointer;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: white;
  z-index: 1;
}

.dropdown-content li {
  padding: 10px 14.5px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  width: 171px;
  font-size: 12px;
  font-weight: 600;
}
.dropdown-content li:hover {
  background: #cecccc;
}

.show-dropdown {
  display: block;
}

.reviews-filter-buttons {
  display: flex;
}
.reviews-filter-btn {
  padding: 8px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  border: #b4b2b2 1px solid;
  background: white;
  cursor: pointer;
  color: black;
  border-radius: 5px;
}
.selected-review-btn {
  border: #b4b2b2 1px solid;
  background: black;
  color: white;
}
.selected-review-btn svg {
  color: white;
}
.reviews-filter-btn:hover {
  border: black 1px solid;
}

.reviews-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-review-btn {
  border: black 1px solid;
  padding: 15px 40px;
  font-weight: 600;
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  color: black;
  border-radius: 5px;
}
.review-form-panel {
  position: fixed;
  top: 0;
  left: -500px;
  width: 400px;
  height: 100vh;
  background-color: white;
  padding: 40px;
  transition: left 0.8s ease;
  color: black;
  box-shadow: 1px 0 5px -2px #888;
}
.close-icon-container {
  display: flex;
  align-items: flex-end;
  justify-content: right;
}
.close-icon {
  cursor: pointer;
}
.rating-input-container {
  display: flex;
  flex-direction: column;
}
.message-input-container {
  display: flex;
  flex-direction: column;
}
.message-input-container textarea {
  height: 200px;
  background: white;
  color: black;
  padding: 20px;
  border: #e5e0e0 1px solid;
}
.rating-form-btn {
  border: #1f8900 1px solid;
  padding: 15px 40px;
  font-weight: 600;
  cursor: pointer;
  background: #1f8900;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  font-size: 15px;
  border-radius: 5px;
}

.review-form-panel.active-form {
  left: 0;
}

.read-more-reviews {
  border: black 1px solid;
  padding: 15px 20px;
  font-weight: 600;
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  width: 220px;
  color: black;
  border-radius: 5px;
}
.add-review-btn:hover,
.read-more-reviews:hover {
  background: black;
  color: white;
}
.reviews-count-container h1 {
  font-size: 36px;
}
.reviews-count-container {
  display: flex;
  align-items: center;
}

.reviews-stars-top {
  display: flex;
  justify-content: center;
}
.reviews-stars-bottom {
  margin-left: 10px;
}
.reviews-stars-bottom svg {
  color: #2ada71;
}
.reviews-stars-top svg {
  /*margin-top: 5px;*/
  color: gold;
}

.court-header-extra-content li a {
  margin-left: 40px;
  color: #6950f3;
  text-decoration: none;
}

.open-time {
  color: #1f8900;
}

.court-open-status {
  color: green;
}

.court-close-status {
  color: black;
}

.services-section-body {
  display: flex;
  /*margin: 10px;*/
}

.service-card {
  background-color: #f1f5f9;
  margin: 10px;
  border-radius: 10px;
  min-height: 400px;
  width: 100%;
}

.service-card h3 {
  margin-top: 5px;
  padding-left: 20px;
}

.special-note-section {
  background-color: #f1f5f9;
  height: 50%;
  margin: 25px;
  border-radius: 10px;
  padding: 30px;
}

.special-note-section h3 {
  color: #74ab3d;
}

.review-input-wrapper button {
  border: none;
  cursor: pointer;
}

.review-input-wrapper button:hover {
  opacity: 0.8;
}

.review-input-wrapper input {
  color: black;
  font-size: 1.1rem;
  font-weight: 500;
}

/*.review-input-wrapper input:focus {*/
/*  outline: 1px solid black;*/
/*}*/

.review-input-wrapper input::placeholder {
  color: rgba(19, 19, 19, 0.4);
}
.review-input-wrapper {
  width: 300px;
  max-width: 100%;
  position: relative;
  margin-top: 10px;
}

.review-input-wrapper input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid darkgray;
  border-radius: 50px;
  padding: 12px 0 12px 33px;
  width: 100%;
  outline: none;
}

.review-input-wrapper button {
  background: green;
  border-radius: 50px;
  aspect-ratio: 1;
  height: 90%;
  position: absolute;
  top: 4.5%;
  right: -30px;
}
.reviews-section {
  margin-left: 20px;
}

.reviews-section-stars {
  font-size: 30px;
  cursor: pointer;
}

.reviews-section-body {
  margin: 10px;
  padding: 0 20px;
}

.reviews-section .reviews-section-body .review-user-input {
  margin-top: 20px;
}

.reviews-section .reviews-section-customer-reviews {
  /*display: grid;*/
  /*grid-template-columns: auto auto auto ;*/
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.reviews-section .reviews-section-customer-reviews .review-container {
  width: 30%;
  background-color: #f1f5f9;
  border-radius: 10px;
  padding: 10px 10px 10px 5px;
  margin: 10px 10px 10px 0;
  position: relative;
}
.reviewer-name{
  font-weight: 500;
}

.reviews-section
  .reviews-section-customer-reviews
  .review-container
  .name-and-pic {
  display: flex;
  align-items: center;
}
.reviews-section
  .reviews-section-customer-reviews
  .review-container
  .name-and-pic
  img {
  height: 50px;
  border-radius: 50%;
  padding-right: 10px;
}
.reviews-section
  .reviews-section-customer-reviews
  .review-container
  .customer-stars-and-review {
}

.reviews-section
  .reviews-section-customer-reviews
  .review-container
  .customer-stars-and-review
  .review-message {
  margin-top: 15px;
}
.reviews-section
  .reviews-section-customer-reviews
  .review-container
  .review-date {
  margin: 0;
  color: #8c8383;
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 13px;
  font-weight: 200;
}

.about-section {
  padding: 0;
  margin-left: 20px;
}

.courts-section-gallery {
  width: 100%;
}

.courts-section-gallery .courts-gallery {
  display: flex;
  justify-content: center;
}

.courts-section-gallery .courts-gallery img {
  width: 100%;
  border-radius: 10px;
}

.courts-section-gallery .courts-gallery .courts-gallery-left-column {
  padding: 10px;
  width: 100%;
  height: 100%;
}

.courts-section-gallery .courts-gallery .courts-gallery-right-column {
  padding: 10px;
  width: 50%;
  height: 100%;
}

.courts-section-gallery
  .courts-gallery
  .courts-gallery-right-column
  .right-upper-column {
  margin: 0;
  height: 50%;
}

.courts-section-gallery
  .courts-gallery
  .courts-gallery-right-column
  .right-lower-column {
  margin: 0;
  height: 50%;
  padding-bottom: 5px;
}

.courts-section-gallery .grid-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  display: block;
}
.services-section > h2 {
  margin-left: 20px;
}

.services-section .service-card-nav {
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 30px;
  display: flex;
  width: 50%;
}

.services-section .service-card-nav a {
  margin: 20px 10px 10px;
  padding: 10px 20px;
  width: 80px;
  text-align: center;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
}

.services-section .service-card-nav a:hover {
  background-color: #000000;
  color: #c5c6d0;
}

.services-section .service-content {
  margin: 20px;
}

.services-section .service-content p {
  text-align: justify;
  padding-left: 20px;
}

.service-amenities h2 {
  padding-left: 20px;
}
/*date-time-css*/
.date-time-bar {
  background-color: #057113;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 20px 60px;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}
.date-time-bar-topic > h3 {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  margin-top: 0px;
}
.date-time-bar-topic > p {
  font-size: 18px;
  color: #ccc;
  margin-bottom: 5px;
}
.date-time-bar-cross svg {
  fill: #fff;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.date-time-container {
  width: auto;
  background-color: #f5f7f2;
  display: flex;
  margin: 40px;
  padding: 10px;
  border-radius: 22px;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}
.date-time-booking {
  background-color: #fff;
  width: 350px;
  padding: 30px;
  border-radius: 15px;
  margin: 120px 30px 60px 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.date-time-booking > h3 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  margin: 20px 0 10px;
}

.date-time-booking-court-btn {
  margin: 8px 8px 8px 0px;
  padding: 4px 1px;
  border: 1px solid #74ab3d;
  border-radius: 6px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  color: #74ab3d;
  background-color: #fff;
  width: 160px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.date-time-booking-court-btn.selected {
  background-color: #70a936;
  color: #ffffff;
}

/*.date-time-booking-court-btn:hover {*/
/*  background-color: #70a936;*/
/*  color: #fff;*/
/*}*/

.date-time-booking-1 {
  width: 400px;
  height: auto;
  /* max-width:400px; */
  padding: 0px 0px 30px 0px;
  border-radius: 15px;
  margin: 60px 0;
  font-family: "Poppins", sans-serif;
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); */
}

button.Kq.Ez::after {
  width: 0;
}
button.Kq.WP::after {
  width: 0;
}

.time-scroll-container {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 10px;
  width: 360px;
}
.payment-container-border {
  border: 1px solid #70a936;
  width: auto;
  border-radius: 15px;
}
.payment-container {
  background-color: #fff;
  border-radius: 15px;
  margin: 10px;
  padding: 20px;
  width: auto;
  height: auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
.payment-container > h3,
.address {
  text-align: center;
}
.payment-container > p,
.booked-courts-1 > p {
  color: #878c93;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}
.payment-container > hr {
  border-color: #fefefe;
}
.payment-container > h4 {
  margin: 5px 0;
}
.booked-courts-1 > h4 {
  margin-bottom: 5px;
}
.booked-courts-1-time {
  display: flex;
  justify-content: space-between;
}
.booked-courts-1-time > p,
.booked-price > p {
  margin: 3px;
}
.booked-total {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.next-btn {
  margin-top: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #020e23;
  border-radius: 8px;
  margin: 60px 20px 0px 20px;
  cursor: pointer;
}
.next-btn > button {
  margin: 10px;
  background-color: transparent;
  color: #fff;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.your-booking {
  /*margin: 0px 0 5 0px;*/
  text-align: center;
  font-family: "Poppins", sans-serif;
}

/*select-booking-css*/
.date-time-booking-type {
  background-color: #fff;
  width: 500px;
  padding: 30px;
  border-radius: 15px;
  margin: 60px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.container input:checked ~ .checkmark {
  background-color: #70a936;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.date-time-booking-type form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.container {
  margin: 20px 0;
  font-size: 16px;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-weight: 600;
}
.container > p {
  font-size: 14px;
  /* padding-left: 30px; */
  font-weight: 400;
}

.service-policies ul {
  color: #757676;
}

.tokens-content {
  display: flex;
  margin: 50px;
}

.tokens-content .individual-token {
  cursor: pointer;
  display: block;
  background-color: #15111e;
  width: 45%;
  border-radius: 30px;
  padding: 40px;
  /* padding-left: 40px;
  padding-right: 40px; */
  margin: 15px;
  font-size: 30px;
  font-weight: bold;
}

.tokens-content .individual-token p {
  margin: 0;
}

.tokens-content .individual-token .token-header {
  display: flex;
  justify-content: space-between;
  font-family: aquawax;
  font-weight: normal;
  color: #4dea5d;
}
.tokens-content .individual-token .token-body {
  padding-top: 10px;
}

.tokens-content .individual-token .token-body p {
  color: white;
  width: 70%;
  font-family: aquawax;
  font-weight: bolder;
  font-size: 80px;
  margin: 0;
}

.tokens-content .individual-token .token-body .last-word {
  margin-top: -35px;
}

.tokens-content .individual-token .token-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tokens-content .individual-token .token-footer .token-footer-duration {
  display: flex;
}

.tokens-content .individual-token button {
  background-color: #4dea5d;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  font-size: 20px;
  font-family: aquawax;
  font-weight: bolder;
  cursor: pointer;
}

.tokens-content .individual-token button:hover {
  background-color: green;
}
.profile-container {
    background: #020E23;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.edit-profile-card {
    /*background-color: rgba(19, 19, 19, 0.4);*/
    /*padding: 20px;*/
    /*border-radius: 10px;*/
    /*max-width: 400px;*/
    padding-top: 20px;
    width: 80%;
    display: flex;
    margin-bottom: 50px;
}

.edit-profile-heading {
    margin-bottom: 20px;
    color: white;
    width: -webkit-fill-available;
    font-family: 'helveticaneueltw06-93blkextRg', serif;
    display: flex;
    justify-content: left;
    padding-left: 150px;
}

.profile-picture {
    margin-bottom: 20px;
}

.profile-picture img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #1F8900;
    margin-bottom: 10px;
}

.profile-name {
    font-size: 18px;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.edit-profile-section {
    margin-bottom: 15px;
}

.edit-profile-content{
    width: 55%;
    padding-right: 100px;
    border-right: 3px solid #1F8900;
}

.edit-profile-section label {
    color: grey;
    display: block;
    padding-left: 8px;
    font-size: small;
    font-weight: 500;
}
input {
  color-scheme: dark;
}

.edit-profile-section input[type="text"],
.edit-profile-section input[type="email"],
.edit-profile-section input[type="tel"] ,
.edit-profile-section input[type="date"] {
    width: 90%;
    padding: 8px;
    border: none;
    color: white;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    text-decoration: none;
    font-size: large;
}

.edit-profile-section .gender-checkboxes {
    display: flex;
    justify-content: center;
}

.edit-profile-section.gender-checkboxes label {
    margin-right: 15px;
    display: inline-block;
}

.edit-profile-section-buttons{
    display: flex;
    padding-top: 20px;
    justify-content: center;
}

.edit-profile-section-buttons button{
    width: 80px;
    padding: 5px 15px;
    margin-right: 20px;
    border-radius: 5px;
    font-weight: bold;
}

.edit-profile-section-buttons .save-button {
    background-color: #1F8900;
    border: none;

}

.edit-profile-section-buttons .cancel-button{
    background-color: transparent;
    color: white;
    border: 2px solid #1F8900;
}

.save-button:hover {
    background-color: #4DEA5D;
}

.edit-profile-password{
    width: 100%;
    padding-left: 100px;
}
.edit-profile-password h3{
    margin-bottom: 20px;
    color: white;
}

.edit-profile-password-section{
    margin-bottom: 15px;
}

.edit-profile-password input[type="password"] {
    width: 50%;
    padding: 8px;
    border: none;
    color: white;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    text-decoration: none;
    font-size: large;
}

.edit-profile-password label{
    color: grey;
    display: block;
    padding-left: 8px;
    font-size: small;
    font-weight: 500;
}

.edit-profile-password .edit-profile-password-section-buttons{
    display: flex;
    padding-top: 20px;
    padding-left: 100px;
}

.edit-profile-password .edit-profile-password-section-buttons button{
    padding: 5px 15px;
    margin-right: 20px;
    border-radius: 5px;
    font-weight: bold;
}

.edit-profile-password .edit-profile-password-section-buttons .change-btn{
    background-color: #1F8900;
  border: none;
}

.edit-profile-password .edit-profile-password-section-buttons .cancel-btn{
    border: 2px solid #1F8900;
    background-color: transparent;
    color: white;
}

.green{
    color: #4DEA5D;
}

.white {
  color: white;
}

@media screen and (max-width: 768px) {
  .nav-container .sign-in-buttons {
    z-index: 99;
    margin-right: 10px;
  }

  .date-time-booking-type {
    background-color: #fff;
    width: 250px;
    padding: 30px;
    border-radius: 15px;
    margin: 10px;
    box-shadow: 0px 0px 15px rgba(183, 34, 34, 0.1);
  }
  .date-time-container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    justify-content: center;
    align-items: center;
  }

  .date-time-booking {
    margin: 10px 20px 10px 20px;
    width: 280px;
    padding: 0px 20px 20px 20px;
  }

  .date-time-booking-1 {
    width: fit-content;
    margin: 20px;
  }
  .payment-container-border,
  .payment-container {
    width: fit-content;
    min-width: 250px;
  }
  .date-time-booking-court-btn {
    width: 130px;
    font-size: 12px;
    margin: 7px 9px 7px 1px;
    padding: 5px 0.5px;
  }

  .date-time-booking-court-btn.not-selected {
    background-color: #fff;
    color: #70a936;
  }

  .your-booking {
    display: none;
  }
  .time-scroll-container {
    width: 280px;
  }
  .booked-courts-1-time > p,
  .booked-price > p {
    font-size: 14px;
  }
}

.service-card-nav .selected {
  background-color: #000000;
  padding: 10px 20px;
  color: #c5c6d0;
}

.image-carousal {
  display: none;
}

.login-page {
  font-family: 'Poppins', sans-serif;
  background-image: url("./asserts/Website-BG.png");
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.sign-logo {
  display: block;
  margin: 0 auto;
  width: 200px;
}

.login-form-container {
  margin: auto;
  text-align: center;
  justify-content: center;
  height: auto;
  border-radius: 5px;
  border: 2px solid #1F8900;
  padding: 20px;
  box-sizing: border-box;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(19, 19, 19, 0.4);
}

.login-form-container form {
  width: 100%;
}

.login-form-container h2 {
  text-align: center;
  color: white;
}

.login-form-container p{
  color: #1F8900;
}

.login-form-container a {
  color: #757676;
  cursor: pointer;
  text-decoration: none;
}

.google-sign-in {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-group {
  margin-bottom: 15px;
}
.login-form-group label {
  display: block;
}

.login-form-group input[type="text"],
.login-form-group input[type="email"],
.login-form-group input[type="password"],
.login-form-container button {
  width: 85%;
  padding: 10px;
  margin-top: 5px;
  border: none;
  border-bottom: 2px solid grey;
  background: none;
  color: white;
  box-sizing: border-box;
}

.login-form-container button {
  background-color: #1F8900;
  width: 150px;
  font-size: 16px;
  border-radius: 50px;
  color: white;
  cursor: pointer;
}

.login-form-container button:hover {
  background-color: #000000;
}

.signup-page {
  font-family: 'Poppins', sans-serif;
  background-image: url("./asserts/Website-BG.png");
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.signup-content h2,
.signup-content p {
  text-align: center;
}

.signup-content h2 {
  color: white;
}

.signup-content p{
  color: #1F8900;
}

.signup-content {
  margin: 10px 0;
  text-align: center;
  justify-content: center;
  height: auto;
  border: 2px solid #1F8900;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(19, 19, 19, 0.4);
  color: white;
}

.signup-content button {
  background-color: #1F8900;
  width: 150px;
  font-size: 16px;
  padding: 10px;
  border-radius: 50px;
  color: white;
  cursor: pointer;
}

.signup-content button:hover {
  background-color: #333333;
}

.signup-content form {
  width: 100%;
}

.signup-content a{
  color: #757676;
  cursor: pointer;
  text-decoration: none;
}

.signup-form-group {
  margin-bottom: 15px;
}

.signup-form-group label {
  margin-bottom: 5px;
}

.signup-form-group input[type="text"],
.signup-form-group input[type="email"],
.signup-form-group input[type="tel"],
.signup-form-group input[type="password"],
.signup-form-group input[type="date"],
.signup-form-group select {
  width: 85%;
  padding: 10px;
  margin-top: 5px;
  border: none;
  border-bottom: 2px solid grey;
  background: none;
  color: white;
  box-sizing: border-box;
}
.signup-form-group input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.signup-form-group button[type="submit"] {
  background-color: #000000; /* Black button background */
  color: #ffffff; /* White button text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.signup-form-group option {
  color: black;
}

.signup-form-group button[type="submit"]:hover {
  background-color: #333333; /* Darker shade on hover */
}

@media screen and (max-width: 768px) {
  .profile-dropdown-content {
    width: 200px;
    margin-right: 10px;
  }
  .profile-dropdown-content > button {
    padding: 12px 116px 12px 16px;
  }

  .profile-dropdown-content .wallet-nav-content {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* .profile-dropdown-content .dropdown-edit-profile{
    display: block;
  } */

  .profile-dropdown-content .dropdown-edit-profile .profile-and-name {
    display: inline-flex;
  }

  .courts-section-gallery .courts-gallery {
    display: none;
  }
  .image-carousal {
    display: block;
  }

  .my-bookings .my-booking-courts {
    display: block;
  }

  .my-bookings .my-booking-courts .my-booking-courts-card {
    width: 100%;
    margin-bottom: 10px;
  }

  .courts-section {
    margin: 0;
    padding: 0;
  }

  .courts-nav-container {
    height: 50px;
    padding: 0 10px;
  }
  .courts-nav-container .courts-logo img {
    height: 50px;
    cursor: pointer;
  }

  .courts-nav-container .courts-logo {
    display: flex;
  }

  .courts-nav-container .sign-in-buttons a {
    font-size: 12px;
    padding: 5px 10px;
    margin-right: 10px;
  }
  .nav-container .sign-in-buttons a {
    font-size: 12px;
    padding: 5px 10px;
    margin-right: 10px;
  }

  .courts-nav-container .sign-in-buttons .sign-in-btn {
    padding: 5px 10px 5px 10px;
  }
  .nav-container .sign-in-buttons .sign-in-btn {
    padding: 5px 10px 5px 10px;
  }

  .courts-section-body {
    margin: 0;
  }
  .court-header-container {
    padding: 20px 20px 0 20px;
  }
  .services-section {
    padding: 0 20px;
  }

  .reviews-header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .review-select-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 30px;
  }
  .add-review-btn {
    border: black 1px solid;
    padding: 10px 40px;
    font-weight: 600;
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 330px;
    margin: auto;
  }
  .read-more-reviews {
    margin-left: 20px;
  }

  .review-form-panel {
    position: fixed;
    top: 0;
    left: -500px;
    width: 315px;
    height: 100dvh;
    padding: 30px 30px 30px 30px;
    transition: left 0.8s ease;
  }
  .review-form-panel h1 {
    font-size: 26px;
  }
  .rating-input-container {
    display: flex;
    flex-direction: column;
  }
  .message-input-container {
    display: flex;
    flex-direction: column;
  }
  .message-input-container textarea {
    height: 200px;
  }
  .rating-form-btn {
    border: black 1px solid;
    padding: 15px 40px;
    font-weight: 600;
    cursor: pointer;
    background: black;
    color: white;
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .reviews-section {
    margin: 0;
  }

  .reviews-section .reviews-section-body {
    margin: 0;
  }

  .reviews-section-header {
    padding: 0 20px;
  }

  .reviews-section-header h2 {
    margin-bottom: 0;
    font-size: 21px;
  }

  .reviews-section .reviews-section-customer-reviews {
    /*display: grid;*/
    /*grid-template-columns: auto auto;*/
    margin: 10px;
  }

  .reviews-section .reviews-section-customer-reviews .review-container {
    width: 90%;
    margin: 5px;
  }

  .about-section {
    padding: 0 20px;
  }
  .address-li {
    color: #0089bb;
  }
  .court-header-content button {
    position: absolute;
    padding: 5px 15px;
    font-size: 20px;
    top: 4px;
  }
  .court-header-extra-content li {
    margin: 4px 0;
    font-size: 13px;
  }
  .court-header-extra-content li a {
    color: #1f8900;
    margin-left: 0;
  }
  .dot {
    display: none;
  }

  .grid-item-1,
  .grid-item-2,
  .grid-item-3 {
    display: none;
  }

  .court-details-header {
    width: 100%;
    margin: 0;
    padding: 0;
    flex-direction: column-reverse;
  }
  .mobile-court-name > h1 {
    text-align: left;
    margin: 0px;
    font-family: "helveticaneueltw06-93blkextRg", serif;
    font-size: 25px;
    display: block;
  }
  .court-header-content > h1 {
    display: none;
    font-size: 17px;
  }
  .courts-section-gallery {
    display: block;
    margin: 0;
  }
  .service-card {
    height: auto;
    margin: 0;
  }
  .services-section > h2 {
    margin-left: 0;
    font-size: 21px;
  }
  .services-section .service-card-nav {
    padding-top: 20px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    width: 100%;
  }
  .services-section-body {
    margin: 0;
  }
  .services-section .service-content p,
  .service-card h3,
  .service-amenities h2 {
    padding-left: 0;
    font-size: 13px;
  }
 .about-section p,
 .special-note-section span{
   font-size: 13px;
 }

  .service-policies ul {
    font-size: 13px
  }

  .services-section .service-card-nav a {
    margin: auto;
    font-size: 13px;
  }

  .futsal-container h1{
    font-size: 19px;
  }
  .futsal-container > p{
    font-size: 15px;
  }
  .reviews-section .reviews-section-customer-reviews .review-container .review-date {
    font-size: 11px;
  }
  .reviews-section-header form {
    margin-top: 10px;
  }

  .reviews-section-header form input[type="text"] {
    width: 100%;
  }

  .reviews-section-stars {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .reviews-section-stars svg {
    margin-right: 5px;
  }
  .court-header-extra-content ul {
    flex-direction: column;
    text-align: start;
    align-content: start;
    align-items: start;
  }
  .review-li svg{
    font-size: 13px;
  }
  .review-total {
    font-size: 13px;
  }
  .tokens-content {
    display: block;
    margin: 20px;
  }

  .tokens-content .individual-token {
    width: auto;
    border-radius: 15px;
    padding: 20px;
  }

  .tokens-content .individual-token p {
    font-size: 16px;
    margin: 0;
  }
  .tokens-content .individual-token .token-body p {
    font-size: 40px;
    padding: 0;
  }

  .tokens-content .individual-token .token-body .last-word {
    margin-top: -15px;
  }

  .tokens-content .individual-token button {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #directing-lines {
    display: none;
  }
  .login-page {
    flex-direction: column;
  }

  .login-form-container {
    margin: 0;
    width: 90%;
    border-radius: 20px;
    height: auto;
  }

  .login-form-container form {
    width: 100%;
  }

  .signup-page {
    flex-direction: column;
  }
  .signup-content {
    display: block;
    width: 80%;
  }

    .signup-content form{
        width: 100%;
    }

    .edit-profile-card{
      padding-top: 0;
      display: block;
    }

  .edit-profile-heading{
    padding: 0;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
  }

    .edit-profile-content{
      border-right: none;
      border-bottom: 2px solid #1F8900;
      padding-right: 0;
      padding-bottom: 20px;
      width: 100%;
    }

    .edit-profile-content .profile-picture{
      display: flex;
      justify-content: center;
    }

    .edit-profile-password{
      padding: 0;
    }
  .edit-profile-password input[type="password"] {
    width: 90%;
  }
  .edit-profile-password .edit-profile-password-section-buttons {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-left: 0;
  }

}

/*all-futsal*/
.view-all-futsal-header {
  background-color: #272e25;
  width: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.view-all-futsal-header > h2 {
  margin: 0;
  padding-bottom: 20px;
  font-size: 36px;
  text-align: center;
  font-weight: 700;
  font-family: "helveticaneueltw06-93blkextRg", serif;
  color: #c7fc27;
  letter-spacing: 3px;
}
.view-all-futsals-card-filters {
  display: flex;
  align-items: center;
}
.view-all-futsals-card-filters svg {
  color: #7d7575;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
}

.view-all-buttons-container {
  display: flex;
  overflow: hidden;
  width: 330px;
}

.view-all-select-location-btn {
  color: #7d7575;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #7d7575;
  border-top: 1px solid #70a936;
  margin: auto 5px;
  /* padding: 5px 12px ; */
  border-radius: 15px;
  height: 28px;
  width: 100px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.view-all-select-location-btn.active {
  background: #74ab3d;
  color: #fff;
  border: none;
}

.view-all-futsals {
  display: flex;
  height: auto;
  justify-content: center;
  background-color: #f5f7f2;
  padding: 70px 0px;
  overflow: hidden;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
.toggle-menu-filter {
  display: flex;
  transform: translateX(210px);
}
.visible-pc {
  transform: translateX(0px);
  animation: slideIn 0.8s ease-in;
}
.hide-pc {
  animation: slideOut 0.8s ease-in;
}

.view-all-futsals-sidebar-mobile {
  display: none;
}
.filter-mobile {
  display: none;
}
.view-all-futsals-sidebar {
  margin-top: 50px;
  padding: 5px 10px 5px 10px;
  width: 180px;
  background-color: #fff;
  height: fit-content;
  border: 2px solid #70a936;
  border-bottom-left-radius: 16px;
  border-right: 1px solid #fff;
  z-index: 0;
}
.filter-container {
  margin-top: 30px;
}
.filter-container-1 {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px;
  background-color: #fff;
  border: none;
  border-radius: 16px;
}
.filter-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 0;
  align-items: center;
}
.filter-header p {
  margin: 0px;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
}

.filter-header svg {
  color: #1f8900;
  margin-right: 6px;
}
.select-filter-btns {
  margin-top: 15px;
}

.select-filter-btn{
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 12px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #74AB3D;
    margin: 0 10px 10px 0;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    border-radius: 28px;
    height: 30px;
    color: #000;

}
.select-filter-btn.active {
  background: #74ab3d;
  color: #fff;
}

/* .view-all-futsals-sort > select, */
.filter-btn {
  outline: none;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  text-align: left;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.view-all-futsals-card-container {
  position: relative;
  /* padding: 40px; */
  /*min-height: 100vh;*/
  background-color: #020e23;
  border-radius: 16px;
  width: 832px;
  /*padding-left: 15px;*/
  padding: 25px 40px;
  /* margin-right: 15%; */
  margin-right: 140px;
}
.view-all-futsals-cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /*grid-template-columns: repeat(3, 1fr);*/
  grid-gap: 40px;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-top: 20px; */
}
.view-all-futsals-cards .no-futsal-container{
  background: #fff;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 400px;
  margin: auto;
  width: 100%;
}
.view-all-futsals-cards > p {
  color: #fff;
}


/*futsal-card*/

.view-all-card {
  border: 1px solid #8c8383;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 2px 2px 4px 2px rgba(178, 222, 39, 0.2);
  width: 162px;
  height: 190px; /*256*/
  border-radius: 10px;
  padding: 7px 7px 12px 7px;
  /* margin: 10px 10px; */
}

.view-all-card-img > img {
  max-width: 100%;
  width: 100%;
  height: 100px;
  border-radius: 8px;
}

.view-all-details {
  text-align: center;
  align-items: center;
}

.view-all-card-details {
  padding: 0 5px 3px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.place {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-weight: 700;
}

.futsal-details .place {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-weight: 700;
}
.futsal-details > .rating {
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.line2 {
  border-top: 1px solid #edecec;
  margin-bottom: 10px;
}
.view-all-card-rate-mobile {
  display: none;
}

.view-all-card-price {
  /* height: 100%; */
  display: flex;
  align-items: flex-end;
  margin-left: 5px;
  margin-top: 3px;
}
.view-all-card-price > h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 11px;
  background: #1f8900;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  margin: 0;
}
.view-all-card-price > h4 span {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  text-align: left;
  font-weight: 300;
}

.first-word-futsal-name-all {
  font-size: 17px;
  text-align: center;
  font-weight: 700;
  font-family: "helveticaneueltw06-93blkextRg", serif;
  margin: 0;
  padding: 3px 0 0 0;
}
.second-word-futsal-name-all {
  font-size: 17px;
  text-align: center;
  font-weight: 700;
  font-family: "helveticaneueltw06-93blkextRg", serif;
  margin-top: -6px;
  margin-bottom: 0;
}

.filter-toggle {
  background-color: #70a936;
  margin-top: 50px;
  padding: 40px 10px;
  height: fit-content;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  cursor: pointer;
}
.filter-toggle p {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: #fff;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-weight: 600;
  letter-spacing: 1px;
}
/* hr.line1 {
  border-top: 1px solid #edecec;
  margin-bottom: 12px;
} */

.rating-form {
  display: flex;
  flex-direction: column;
}
.rating-form svg {
  color: #ffb900;
}
/* custom radio button */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.container input:checked ~ .checkmark1 {
  background-color: #70a936;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark1:after {
  display: block;
}

.container .checkmark1:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.container {
  font-size: 19px;
  margin-top: 5px;
}

@media screen and (max-width: 1300px) {
  .view-all-futsals-card-container {
    width: 776px;
    padding: 0 0 20px 15px;
    margin-right: 200px;
  }
  .view-all-futsals-cards {
    grid-gap: 18px;
    padding-top: 20px;

  }
  .view-all-futsals-cards .no-futsal-container{
    margin-right:20px;
  }
   .view-all-futsals-cards .no-futsal-container img {
    height: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 1246px) {
  .view-all-futsals-card-container {
    width: 592px;
    padding: 0 0 20px 20px;
    margin-right: 225px;
  }
  .view-all-futsals-cards {
    grid-gap: 20px;
  }

  .view-all-futsals-cards .no-futsal-container{
    margin-right:20px;
  }
}

@media screen and (max-width: 768px) {
  .filter-mobile {
    display: block;
  }
  .view-all-futsals-sidebar {
    display: none;
  }
  .filter-toggle {
    display: none;
  }
  .view-all-futsal-header > h2 {
    font-size: 28px;
    padding-bottom: 30px;
  }
  .view-all-card-rate-pc {
    display: none;
  }
  .view-all-futsals-sidebar-mobile {
    display: block;
  }
  .view-all-card-rate-mobile {
    display: flex;
  }

  .filter-mobile {
    background-color: #70a936;
    color: #fff;
    position: fixed;
    bottom: 20px;
    right: 10px;
    z-index: 100;
    padding: 14px 16px;
    border-radius: 50%;
    margin-right: 10px;
  }

  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }

  .view-all-futsals-sidebar-mobile {
    transform: translateY(100%);
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    z-index: 10;
  }
  .view-all-futsals-sidebar-mobile h2 {
    font-family: "Poppins", sans-serif;
  }
  .filter-container {
    margin-top: 0;
  }

  .view-all-futsals-sidebar-mobile.visible {
    background-color: #e8f8ee;
    transform: translateY(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    animation: slideIn 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .view-all-futsals-sidebar-mobile.hidden {
    transform: translateY(100%);
    animation: slideOut 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  /* cards */
  .view-all-futsals {
    padding: 0;
    justify-content: center;
  }
  .view-all-futsals-card-filters {
    padding-bottom: 10px;
  }
  .view-all-buttons-container {
    /* width: 250px; */
    justify-content: space-between;
    max-width: 280px;
  }
  .view-all-select-location-btn {
    font-size: 14px;
    width: fit-content;
    padding: 0 12px;
  }
  .view-all-futsals-card-container {
    margin-right: 0;
    background-color: #020e23;
    border-radius: 0;
    align-items: center;
    padding: 2px;
    min-height: 70dvh;
    padding-top: 23px;
    padding-bottom: 50px;
  }
  .view-all-futsal-card-stroke {
    /* margin: 10px auto 36px; */
    margin-bottom: 10px;
    background: transparent;
    width: 361px; /*369*/
    height: 91px; /*105*/
    box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae,
      0 0 0 1px #34ee98, 0 0 0 1px #43ea80;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 15px;
  }
  .view-all-futsals-cards {
    justify-content: center;
    align-items: flex-start;
    /* min-height: 70dvh; */
  }
  .view-all-card {
    display: flex;
    flex-direction: row;
    border-radius: 11px;
    width: 350px;
    padding: 0;
    height: 80px;
    box-shadow: none;
  }
  .view-all-futsals-cards .no-futsal-container{
    margin: 0 20px;
    height: 250px;
  }

  .view-all-card-img > img {
    max-width: 110px;
    border-radius: 11px;
    width: 110px;
    height: 80px;
  }

  .futsal-name {
    margin-left: 5px;
  }
  .futsal-name > p {
    margin: 0px;
    text-align: left;
    font-size: 9px;
  }
  .rating-mobile {
    font-size: 11px;
    margin: 0;
  }
  .first-word-futsal-name-all {
    font-size: 16px;
    text-align: left;
  }
  .second-word-futsal-name-all {
    font-size: 16px;
    text-align: left;
  }
  hr.line1 {
    /*display: none;*/
  }
  .view-all-card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .view-all-details {
    display: flex;
    flex-direction: row;
    width: inherit;
    margin-left: 3px;
  }

  .view-all-card-details p,
  .view-all-card-details .rating {
    font-size: small;
    margin: 0;
  }
  .futsal-details p {
    /* margin-bottom: 7px; */
    font-size: 12px;
  }

  .view-all-card-price {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  .view-all-card-price > h4 {
    font-size: 14px;
    /* text-align: left; */
  }
  .filter-apply-btn {
    background-color: #70a936;
    color: #fff;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 16px;
    /* height: 32px; */
    margin-top: 10px;
    border: 1px solid #74ab3d;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    border-radius: 20px;
  }
}

/*get-in-touch-form*/
.get-in-touch-container {
  background-image: url("../src/asserts/Bar.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.get-in-touch-form {
  margin-right: 80px;
}
.get-in-touch-form h3 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  margin: 0;
  color: #fff;
}
.get-in-touch-form .form-element {
  margin: 10px 0;
}
.get-in-touch-form .form-element input {
  border: 1px solid #79e840;
  box-shadow: 0 0 3px #79e840;
  background-color: transparent;
  border-radius: 6px;
  width: 100%;
  padding: 8px 15px;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #fff;
}
.get-in-touch-form button {
  border-radius: 5px;
  background: #79e840;
  color: #000;
  cursor: pointer;
  padding: 4px 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  border: none;
  font-size: 15px;
  width: 100px;
}
.get-in-touch-text {
  font-family: "Poppins", sans-serif;
  text-align: left;
}
.get-in-touch-text h3 {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
}
.get-in-touch-text h1 {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
  margin-top: -42px;
}
.get-in-touch-text h4 {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
  margin-top: -62px;
}
.get-in-touch-text h2 {
  color: #79e840;
  font-size: 55px;
  font-weight: 700;
  font-style: italic;
  margin-top: -92px;
  margin-left: -8px;
}
.get-in-touch-text p {
  display: none;
}

@media screen and (max-width: 768px) {
  .get-in-touch-container {
    flex-direction: column-reverse;
  }
  .get-in-touch-text {
    margin-top: -25px;
    margin-right: 0px;
    text-align: left;
    justify-content: left;
    width: 215px;
  }
  .get-in-touch-text h3 {
    font-size: 18px;
    margin-top: 0;
  }
  .get-in-touch-text h1 {
    font-size: 35px;
    font-weight: 600;
    margin-top: -32px;
  }
  .get-in-touch-text h4 {
    font-size: 35px;
    font-weight: 600;
    margin-top: -40px;
  }
  .get-in-touch-text h2 {
    font-size: 35px;
    margin-top: -60px;
  }
  .get-in-touch-text h2 {
    margin-left: -5px;
  }
  .get-in-touch-form {
    margin-right: 0px;
  }

  .get-in-touch-form form {
    align-items: center;
    justify-content: center;
  }
  .get-in-touch-form h3 {
    text-align: left;
    font-size: 16px;
    margin-top: -20px;
    margin: 0;
  }
  .get-in-touch-form .form-element {
    margin-top: 2px;
    margin-bottom: 10px;
  }
  .get-in-touch-form .form-element input {
    width: 200px;
    padding: 8px 10px;
    font-size: 14px;
  }
  .get-in-touch-form button {
    width: 150px;
    padding: 5px 25px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    border: none;
  }
}

/*my booking*/
.my-booking {
  margin: 60px auto;
  max-width: 1280px;
}
.my-booking-title {
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;
}
.my-booking-header {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-booking-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 15px;
}
.my-booking-container > p {
  margin-left: 20px;
}
.header-btn {
  outline: none;
  border: none;
  padding: 15px;
  cursor: pointer;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  position: relative;
  overflow: hidden;
}

.header-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 5px;
  width: 100%;
  height: 4px;
  background-color: #057113;
  opacity: 0;
}

.header-btn:hover::after {
  opacity: 1;
}
.header-btn.active::after {
  opacity: 1;
}

.my-booking-dropdown {
  /* margin-right: 50px; */
  height: 42px;
  width: 200px;
  padding: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
}
.my-booking-dropdown > svg {
  size: 20;
}
.my-booking-dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  background: white;
}
.my-booking-dropdown-content li {
  display: block;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  padding: 6px 25px;
  width: 150px;
}
.show-dropdown {
  display: block;
}
.my-booking-dropdown-content li:hover {
  background: #ccc;
}

/*my booking card*/
.my-booking-card {
  width: 280px;
  height: 280px;
  background-color: #0a5c36;
  position: relative;
  border-radius: 9px;
  margin: 20px 20px;
  cursor: pointer;
  box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae,
    0 0 0 1px #34ee98, 0 0 0 1px #43ea80;
}

.my-booking-card h1 {
  font-weight: 600;
  font-size: x-large;
  position: absolute;
  top: 9%;
  /*color: #15111E;*/
  color: white;
  width: 280px;
  justify-content: center;
  text-align: center;
}
.my-booking-card h3 {
  font-weight: 400;
  font-size: small;
  position: absolute;
  top: 20%;
  /*color: #15111E;*/
  color: white;
  width: 280px;
  justify-content: center;
  text-align: center;
}
.my-booking-card > .line {
  width: 75%;
  height: 2px;
  border-bottom: 2px dashed #c7bebe;
  position: absolute;
  top: 37%;
  left: 12%;
}
.my-booking-card .half-circle {
  top: 35%;
  transform: translate(-50%, -50%);
  height: 15px;
  width: 30px;
  border-radius: 150px 150px 0 0;
  background-color: #f1f5f9;
  position: absolute;
  /*box-shadow: 0 0 0 1px #38f8d4, 0 0 0 1px #2af5c2, 0 0 0 1px #29f2ae, 0 0 0 0px #34ee98, 0 0 0 1px #43ea80;*/
  border-bottom: 0px solid green;
  border-right: 1px solid #2af5c2;
  border-left: 1px solid #29f2ae;
  border-top: 1px solid #43ea80;
}
.my-booking-card .court-heading {
  position: absolute;
  top: 40%;
  left: 13%;
  font-size: x-small;
  font-weight: 600;
  color: #ccc;
}
.my-booking-card .booking-id-heading {
  position: absolute;
  top: 40%;
  right: 13%;
  font-size: x-small;
  font-weight: 600;
  color: #ccc;
}
.my-booking-card .date-heading {
  position: absolute;
  /* top: 60%; */
  top: 69%;
  left: 13%;
  font-size: x-small;
  font-weight: 600;
  color: #ccc;
}
.my-booking-card .time-heading {
  position: absolute;
  /* top: 60%; */
  top: 69%;
  right: 13%;
  font-size: x-small;
  font-weight: 600;
  color: #ccc;
}
.my-booking-card .court {
  position: absolute;
  top: 44%;
  left: 16%;
  font-weight: 600;
  font-size: small;
  /*color: #15111E;*/
  color: white;
}
.my-booking-card .booking-id {
  position: absolute;
  top: 44%;
  right: 12%;
  font-weight: 600;
  font-size: small;
  color: #74ab3d;
}
.my-booking-card .date {
  position: absolute;
  /* top: 64%; */
  top: 73%;
  left: 13%;
  font-weight: 600;
  font-size: small;
  /*color: #15111E;*/
  color: white;
}
.my-booking-card .time {
  position: absolute;
  /* top: 64%; */
  top: 73%;
  right: 12%;
  font-weight: 600;
  font-size: small;
  /*color: #15111E;*/
  color: white;
}

.my-booking-card .left {
  left: -9px;
  transform: rotate(90deg);
}

.my-booking-card .right {
  right: -9px;
  transform: rotate(270deg);
}
@media screen and (max-width: 1300px) {
  .my-booking {
    margin: 60px 100px;
  }
  .my-booking-title {
    margin-left: 50px;
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 5px;
  }
  .my-booking-header {
    margin-left: 50px;
    padding-left: 0;
    padding-right: 0;
  }
  .my-booking-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
  }
  .my-booking-container p {
    margin-left: 0px;
  }
  .my-booking-card {
    margin: 30px;
  }
  .my-booking-dropdown {
    margin-right: 50px;
  }
}
@media screen and (max-width: 1240px) {
  .my-booking {
    /* margin: 60px 100px;   */
    margin: 60px 170px;
  }
  .my-booking-title {
    margin-left: 0;
  }
  .my-booking-title > h1 {
    text-align: center;
  }
  .my-booking-container {
    padding-left: 0px;
    justify-content: space-between;
  }
  .my-booking-card {
    margin: 20px;
  }
  .my-booking-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .header-btn {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
  }
  .my-booking-dropdown {
    margin-right: 0;
    margin-top: 10px;
    padding: 8px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .my-booking {
    margin: 20px;
  }
  .my-booking-container {
    justify-content: center;
    padding-left: 0px;
  }
  .my-booking-dropdown {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .my-booking-header {
    margin-bottom: 0;
  }
}
