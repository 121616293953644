.view-all-card-skeleton{
    width: 160px;
    height: 190px;
    background: #EBEBEB;
    border-radius: 6px;
    padding: 5px;
}
.view-all-court-image-skeleton{
    margin: auto;
}
.view-all-text-container{
    padding-left: 10px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
}
.view-all-court-price-skeleton{
    margin-left: 10px;

}
@media screen and (max-width: 768px) {
    .view-all-card-skeleton{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 350px;
        height: 80px;
        background: #EBEBEB;
        border-radius: 6px;
    }
    .view-all-text-container{
        padding-left: 10px;
        margin:  0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70px;
    }
    .view-all-court-price-skeleton{
        margin-right: 10px;
    }
}