html {
    scroll-behavior: smooth;
}

.swiper-slide.swiper-slide-visible.swiper-slide-prev,
.swiper-slide.swiper-slide-visible.swiper-slide-next{
    transition-duration: 0ms;

    transform: translate3d(0px, 0px, -253.012px) rotateX(0deg) rotateY(0deg) scale(0.1);
}


.steps-co {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    cursor: pointer;
    background: white;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    vertical-align: bottom;
    margin-top: 20px;
    /*display: table-cell;*/
    /*padding-left: 40px;*/
}

.hovered .steps-co {
    transform: rotateY(180deg);
}

.front-content,
.back-content {
    position: absolute;
    width: 100%;
    height: 20%;
    backface-visibility: hidden;
    border-radius: 25px;
    display: table-cell;
    vertical-align: bottom;
    bottom: 20%;

}

.back-content {
    transform: rotateY(180deg);
    background: white;
    top: 5%;

}
.front-content > h1,
.back-content >p{
    padding-left: 40px;
}


@media (min-width: 1500px) {
    .swiper-slide {
        width: 750px;
        height: 285px;
        position: relative;
        border-radius: 35px;
        padding: 0;
        margin:0 50px 50px 50px;

    }
    /*.steps-co{*/
    /*    width: 750px;*/
    /*    height: 285px;*/
    /*    border-radius: 25px;*/
    /*    object-fit: cover;*/
    /*    padding-left: 40px;*/
    /*    cursor: pointer;*/
    /*    font-family: 'Poppins', sans-serif;*/
    /*    background: white;*/
    /*    vertical-align: bottom;*/
    /*    display: table-cell;*/

    /*}*/

    .swiper_container {
        height: auto;
        padding:  0;
        position: relative;
        width: 100%;

    }

}


.cover-flow-container {
    max-width: 100%;
    padding: 2rem 0;
    background: #020E23;
    /*border-radius: 20px;*/
}
.learn-gearup-head{
    text-align: center;
    color:  #79e840;
    font-family: 'helveticaneueltw06-93blkextRg', serif;
    font-size: 40px;
}



.swiper_container {
    height: auto;
    padding:  0;
    position: relative;

}

.swiper-slide {
    width: 730px;
    height: 300px;
    position: relative;
    border-radius: 29px;
    padding: 0;
    margin:0 50px 50px 50px;

}
.swiper-slide.swiper-slide-visible.swiper-slide-active{
    margin: 0 110px 50px 100px;

}



/*.steps-co{*/
/*    width: 730px;*/
/*    height: 300px;*/
/*    border-radius: 20px;*/
/*    object-fit: cover;*/
/*    padding-left: 40px;*/
/*    cursor: pointer;*/
/*    font-family: 'Poppins', sans-serif;*/
/*    background: white;*/
/*    vertical-align: bottom;*/
/*    display: table-cell;*/

/*}*/
.steps-co >h1{
    font-weight: 600;
    width: 70%;
}
.steps-second-text{
    margin-top: -38px;
}
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;

}

.slider-controler .swiper-button-next {
    width: 100px;
    height: 100px;
    left: 58% !important;
    transform: translateX(-58%) !important;
    background: green;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
    border-radius: 0;
    width: 40px;
    background: white;
    margin: 0;
    transform: skew(-20deg);
    height: 6px;

}
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
    /*margin: 0;*/
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: #1F8900;
    height: 10px;
    width: 50px;
}

@media (max-width: 500px) {
    .cover-flow-container {
        max-width: 100%;
        padding: 2rem 0;
        margin: 0 ;
        background: #020E23;
        border-radius: 0;
    }
    .learn-gearup-head{
        text-align: center;
        color: #1F8900;
        font-family: 'helveticaneueltw06-93blkextRg', serif;
        font-size: 24px;
    }
    .steps-co h1{
        font-weight: 600;
        font-size: 15px;
    }
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
        display: block;
    }
    .front-content > h1,
    .back-content >h1{
        padding-left: 20px;
    }
    .steps-co{
        width: 250px;
        height: 116px;
        border-radius: 20px;
        object-fit: cover;
        margin-top: 0;

    }

    .front-content{
        bottom: 25%;
    }
    .back-content{
        top: 5%;
        height: 100%;
        overflow-y: auto;
    }
    .back-content >p{
        padding-left: 20px;
        font-size: 10px;
    }

    .swiper-slide {
        width: 250px;
        height: 116px;
        position: relative;
        border-radius: 29px;
        margin:0 0 50px 0;


    }
    .swiper-slide.swiper-slide-visible.swiper-slide-active{
        margin: 0 ;

    }

    .steps-second-text{
        margin-top: -20px;
    }
    .swiper-pagination .swiper-pagination-bullet {
        filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
        border-radius: 0;
        width: 30px;
        background: white;
        margin: 0;
        transform: skew(-20deg);
        height: 5px;

    }
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
        /*margin: 0;*/
    }

    .swiper-pagination .swiper-pagination-bullet-active {
        background: #1F8900;
        height: 6px;
        width: 40px;
    }

}




@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    /*left: 6%;*/
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));

}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative;
    width: 24rem !important;
    bottom: 1rem;
    /*margin-top: 80px;*/
}
.swiper-slide.hovered .swiper-pagination,
.swiper-slide.hovered .swiper-pagination.swiper-pagination-bullets {
    margin-top: 50px; /* Adjust the margin-top value as needed */
}

