
.paymentCardSkeleton-container{
    background-color: #EBEBEB;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 310px;
    width:400px;
    margin: 0;
}
 .paymentCardSkeleton-details-container{
   margin: 20px auto;
} 
.paymentCardSkeleton-booking-container{
    padding-left: 20px;
}
.paymentCardSkeleton-pay-container{
    display: flex;
    justify-content: space-between;
    padding: 20px;

}
.paymentCardSkeleton-name-container,
.paymentCardSkeleton-address-container,
.paymentCardSkeleton-time-container,
.paymentCardSkeleton-court-container,
.paymentCardSkeleton-total-container,
.paymentCardSkeleton-value-container {
  padding-top: 8px;
  /* padding-bottom: 8px;  */
}
.paymentCard-name-skeleton,
.paymentCard-address-skeleton,
.paymentCard-time-skeleton,
.paymentCard-court-skeleton,
.paymentCard-total-skeleton,
.paymentCard-value-skeleton{
  background: #cacaca;
}


@media screen and (max-width: 768px){
    .paymentCardSkeleton-container{
        margin: 30px 0;
        width: 300px;
    }
}