.courtSkeleton-container {
    width: 238px;
    height: 284px;
    background-color: #EBEBEB;
    margin-bottom: 50px;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

}

.court-image-skeleton {
    position: absolute;
    top :15px;
    left: 6%;
}
.text-skeleton-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 190px;
    width: 100%;
    height: 70px;
}
.text-name-container{
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.text-price-container{
    display: flex;
    justify-content: space-around;
}
.court-name-skeleton{
    background: #EBEBEB;
}
.court-rating-skeleton{
    background: #EBEBEB;
}
.court-price-skeleton{
    background: #EBEBEB;
}

@media screen and (max-width: 768px){
    .courtSkeleton-container {
        width: 164px;
        height: 198px;
        margin: 0 20px 50px 20px;
    }
    .court-image-skeleton {
        position: absolute;
        top :10px;
        left: 10px;
    }
    .text-skeleton-container{
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 135px;
        width: 100%;
        height: 60px;
    }
    .court-name-skeleton{
      margin-top: 10px;
    }
    .text-price-container{
        margin-bottom: 5px;
    }
}